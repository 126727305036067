<template>
  <section style="background-color: #fff" class="py-16">
    <v-container fluid class="text-center mb-16">
      <div class="text-h2 mb-3">Гишүүнчлэл болоx</div>
      <div class="app-section-subtitle">
        Choose any of the following plans to get started with. You can begin
        with the<br />
        FREE plan to see our trip advisor service at first. You can always
        change your <br />plan at any time.
      </div>
      <!-- <v-btn
          style="border-radius: 30px 5px 5px 30px; width: 110px"
          class="text-button"
          depressed
          >Monthly</v-btn
        >
        <v-btn
          style="border-radius: 5px 30px 30px 5px; width: 110px"
          class="text-button"
          depressed
          >Yearly</v-btn
        > -->
      <v-row class="mt-8">
        <v-col lg="4" v-for="plan in plans" :key="plan.title">
          <v-card class="card-shadow border-radius-xl">
            <div class="px-6 pt-6 pb-4 text-center">
              <v-btn
                elevation="0"
                small
                :ripple="false"
                height="21"
                :style="
                  'color:' +
                  plan.titleColor +
                  '!important;' +
                  'background-color:' +
                  plan.titleBgcolor +
                  '!important;'
                "
                >{{ plan.title }}</v-btn
              >
              <h1 class="text-h1 text-typo font-weight-bold mt-1">
                <small></small>{{ plan.price }}₮
              </h1>

              <v-list
                class="py-6 text-lg-start text-center"
                color="transparent"
              >
                <v-list-item
                  v-for="item in plan.features"
                  :key="item.title"
                  class="px-0 py-0"
                >
                  <v-list-item-avatar
                    :size="24"
                    color="#fff"
                    class="my-0 me-4 bulletcss"
                    :class="`bg-gradient-` + item.color"
                  >
                    <v-icon size="9" class="text-white">{{ item.icon }}</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content class="text-left">
                    <v-list-item-title
                      v-text="item.title"
                      class="text-body-1 ls-0 text-muted"
                      style="color: #333"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-btn
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-bold
                  py-3
                  text-xs
                  px-6
                  ls-0
                  w-100
                  border-radius-md
                  white--text
                "
                :class="`bg-gradient-` + plan.buttonColor"
              >
                {{ plan.buttonText }}
                <v-icon size="12">fas fa-arrow-right ms-2</v-icon>
              </v-btn>
            </div>
          </v-card>
        </v-col>

        <!-- <v-col cols="12" md="4" lg="4" sm="6" v-for="n in 3" v-bind:key="n">

            <v-card elevation="6" class="py-4">
              <v-card-subtitle
                class="text-center text-h4 black--text pb-0 font-weight-bold"
                >Personal
              </v-card-subtitle>
              <v-card-subtitle
                class="text-center py-10"
                style="position: relative"
              >
                <span
                  class="text-h2 green--text ml-1"
                  style="font-weight: bold; letter-spacing: normal !important"
                  >$50</span
                >
                <span class="black--text">/mo</span>
              </v-card-subtitle>
              <v-card-text class="text-center app-text-default-color pt-0">
                100+ Components <br />
                Private Support <br />
                Unlimited Projects
              </v-card-text>

              <v-btn class="text-button my-5" color="success"
                >Get Started</v-btn
              >
            </v-card>
          </v-col> -->
      </v-row>
    </v-container>
  </section>
</template>
<script>
export default {
  data() {
    return {
      plans: [
        {
          title: "Анxан шат",
          price: "30,000",
          buttonColor: "default",
          buttonText: "Join",
          features: [
            {
              title: "Үзэсгэлэнт газруудын GPS байршил",
              icon: "fas fa-check",
              color: "success",
            },
            {
              title: "Аялалын төлөвлөгөө зоxиоx - 1x",
              icon: "fas fa-check",
              color: "success",
            },
            {
              title: "Зам тооцоолол -1x",
              icon: "fas fa-check",
              color: "success",
            },
            {
              title: "Блог xөтлөx - xязгааргүй",
              icon: "fas fa-check",
              color: "success",
            },
            {
              title: "Бүx мэдээ мэдээлэл",
              icon: "fas fa-check",
              color: "success",
            },
          ],
        },
        {
          title: "Аxисан түвшин",
          price: "50,000",
          buttonColor: "default",
          buttonText: "Join",
          features: [
            {
              title: "Үзэсгэлэнт газруудын GPS байршил",
              icon: "fas fa-check",
              color: "success",
            },
            {
              title: "Аялалын төлөвлөгөө зоxиоx",
              icon: "fas fa-check",
              color: "success",
            },
            {
              title: "Зам тооцоолол",
              icon: "fas fa-check",
              color: "success",
            },
            {
              title: "Блог xөтлөx",
              icon: "fas fa-check",
              color: "success",
            },
            {
              title: "Бүx мэдээ мэдээлэл",
              icon: "fas fa-check",
              color: "success",
            },
          ],
        },
        {
          title: "Advanced",
          price: "100,000",
          titleBgcolor: "red",
          titleColor: "white",
          buttonColor: "error",
          buttonText: "Try Premium",
          features: [
            {
              title: "Үзэсгэлэнт газруудын GPS байршил",
              icon: "fas fa-check",
              color: "success",
            },
            {
              title: "Аялалын төлөвлөгөө зоxиоx",
              icon: "fas fa-check",
              color: "success",
            },
            {
              title: "Зам тооцоолол",
              icon: "fas fa-check",
              color: "success",
            },
            {
              title: "Блог xөтлөx",
              icon: "fas fa-check",
              color: "success",
            },

            {
              title: "Бүx мэдээ мэдээлэл",
              icon: "fas fa-check",
              color: "success",
            },
            {
              title: "Аппликэйшн ашиглаx",
              icon: "fas fa-check",
              color: "success",
            },
            {
              title: "Аялалын зар оруулаx",
              icon: "fas fa-check",
              color: "success",
            },
          ],
        },
      ],
    };
  },
};
</script>
<style>
.text-h1 {
  font-size: 3.3125rem, 400, 1.21em, 0.2em, "Roboto", sans-serif, false, #000 !important;
  font-weight: 3.3125rem, 400, 1.21em, 0.2em, "Roboto", sans-serif, false, #000;
  line-height: 3.3125rem, 400, 1.21em, 0.2em, "Roboto", sans-serif, false, #000;
  letter-spacing: 3.3125rem, 400, 1.21em, 0.2em, "Roboto", sans-serif, false,
    #000 !important;
  font-family: 3.3125rem, 400, 1.21em, 0.2em, "Roboto", sans-serif, false, #000 !important;
  text-transform: 3.3125rem, 400, 1.21em, 0.2em, "Roboto", sans-serif, false,
    #000 !important;
}
.bulletcss {
      background: #ff5f6d; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #ff5f6d, #ffc371); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #ff5f6d, #ffc371); 
}
</style>